const environment = "live";
let apiUrl = "http://localhost:5000";
if (environment === "production") {
	apiUrl = "https://api.bitexuae.net";
}
if (environment === "live") {
	apiUrl = "https://api.trillionbit.com";
}
module.exports = {
	apiUrl,
};
