
export const LOGIN = "LOGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGIN_USER_ROLE_REQUEST = "LOGIN_USER_ROLE_REQUEST";
export const LOGIN_USER_ROLE_SUCCESS = "LOGIN_USER_ROLE_SUCCESS";
export const LOGOUT = "LOGOUT"
export const ERRORS = "ERRORS"



