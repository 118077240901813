import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_TRADING_MAINTENANCE_SUCCESS,
	CREATE_TRADING_MAINTENANCE_FAILURE,
	UPDATE_TRADING_MAINTENANCE_SUCCESS,
	UPDATE_TRADING_MAINTENANCE_FAILURE,
	REMOVE_TRADING_MAINTENANCE_SUCCESS,
	REMOVE_TRADING_MAINTENANCE_FAILURE,
	TOGGLE_TRADING_MAINTENANCE_SUCCESS,
	TOGGLE_TRADING_MAINTENANCE_FAILURE,
	CREATE_WALLET_MAINTENANCE_SUCCESS,
	CREATE_WALLET_MAINTENANCE_FAILURE,
	UPDATE_WALLET_MAINTENANCE_SUCCESS,
	UPDATE_WALLET_MAINTENANCE_FAILURE,
	REMOVE_WALLET_MAINTENANCE_SUCCESS,
	REMOVE_WALLET_MAINTENANCE_FAILURE,
	TOGGLE_WALLET_MAINTENANCE_SUCCESS,
	TOGGLE_WALLET_MAINTENANCE_FAILURE,
	// TOGGLE_MAINTENANCE_REQUEST,
	TOGGLE_MAINTENANCE_SUCCESS,
	TOGGLE_MAINTENANCE_FAILURE
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	loader: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				loading: false,
			};

		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};

		case CREATE_TRADING_MAINTENANCE_SUCCESS:
		case CREATE_TRADING_MAINTENANCE_FAILURE:
		case UPDATE_TRADING_MAINTENANCE_SUCCESS:
		case UPDATE_TRADING_MAINTENANCE_FAILURE:
		case REMOVE_TRADING_MAINTENANCE_SUCCESS:
		case REMOVE_TRADING_MAINTENANCE_FAILURE:
		case CREATE_WALLET_MAINTENANCE_SUCCESS:
		case CREATE_WALLET_MAINTENANCE_FAILURE:
		case UPDATE_WALLET_MAINTENANCE_SUCCESS:
		case UPDATE_WALLET_MAINTENANCE_FAILURE:
		case REMOVE_WALLET_MAINTENANCE_SUCCESS:
		case REMOVE_WALLET_MAINTENANCE_FAILURE:
		case TOGGLE_WALLET_MAINTENANCE_SUCCESS:
		case TOGGLE_WALLET_MAINTENANCE_FAILURE:
		case TOGGLE_TRADING_MAINTENANCE_SUCCESS:
		case TOGGLE_TRADING_MAINTENANCE_FAILURE:
		case TOGGLE_MAINTENANCE_SUCCESS:
		case TOGGLE_MAINTENANCE_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};
		default:
			return state;
	}
};

export default reducer;
