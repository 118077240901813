import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_ARTICLE_SUCCESS,
	CREATE_ARTICLE_FAILURE,
	UPDATE_ARTICLE_SUCCESS,
	UPDATE_ARTICLE_FAILURE,
	REMOVE_ARTICLE_FAILURE,
	REMOVE_ARTICLE_SUCCESS,
	FEATURE_ARTICLE_FAILURE,
	FEATURE_ARTICLE_SUCCESS,
	TOGGLE_ARTICLE_SUCCESS,
	TOGGLE_ARTICLE_FAILURE,
	CREATE_ARTICLE_TAG_SUCCESS,
	CREATE_ARTICLE_TAG_FAILURE,
	UPDATE_ARTICLE_TAG_SUCCESS,
	UPDATE_ARTICLE_TAG_FAILURE,
	REMOVE_ARTICLE_TAG_SUCCESS,
	REMOVE_ARTICLE_TAG_FAILURE,
	TOGGLE_ARTICLE_TAG_SUCCESS,
	TOGGLE_ARTICLE_TAG_FAILURE,
	CREATE_ARTICLE_CATEGORY_SUCCESS,
	CREATE_ARTICLE_CATEGORY_FAILURE,
	UPDATE_ARTICLE_CATEGORY_SUCCESS,
	UPDATE_ARTICLE_CATEGORY_FAILURE,
	REMOVE_ARTICLE_CATEGORY_SUCCESS,
	REMOVE_ARTICLE_CATEGORY_FAILURE,
	TOGGLE_ARTICLE_CATEGORY_SUCCESS,
	TOGGLE_ARTICLE_CATEGORY_FAILURE,
	ARTICLE_CATEGORY_SUCCESS,
	ARTICLE_CATEGORY_FAILURE,
	ARTICLE_TAG_FAILURE,
	ARTICLE_TAG_SUCCESS,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	loader: false,
	tags: [],
	categories: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				loading: false,
			};

		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CREATE_ARTICLE_SUCCESS:
		case CREATE_ARTICLE_FAILURE:
		case UPDATE_ARTICLE_SUCCESS:
		case UPDATE_ARTICLE_FAILURE:
		case REMOVE_ARTICLE_FAILURE:
		case REMOVE_ARTICLE_SUCCESS:
		case FEATURE_ARTICLE_FAILURE:
		case FEATURE_ARTICLE_SUCCESS:
		case TOGGLE_ARTICLE_SUCCESS:
		case TOGGLE_ARTICLE_FAILURE:
		case CREATE_ARTICLE_TAG_SUCCESS:
		case CREATE_ARTICLE_TAG_FAILURE:
		case UPDATE_ARTICLE_TAG_SUCCESS:
		case UPDATE_ARTICLE_TAG_FAILURE:
		case REMOVE_ARTICLE_TAG_SUCCESS:
		case REMOVE_ARTICLE_TAG_FAILURE:
		case TOGGLE_ARTICLE_TAG_SUCCESS:
		case TOGGLE_ARTICLE_TAG_FAILURE:
		case CREATE_ARTICLE_CATEGORY_SUCCESS:
		case CREATE_ARTICLE_CATEGORY_FAILURE:
		case UPDATE_ARTICLE_CATEGORY_SUCCESS:
		case UPDATE_ARTICLE_CATEGORY_FAILURE:
		case REMOVE_ARTICLE_CATEGORY_SUCCESS:
		case REMOVE_ARTICLE_CATEGORY_FAILURE:
		case TOGGLE_ARTICLE_CATEGORY_SUCCESS:
		case TOGGLE_ARTICLE_CATEGORY_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};
		case ARTICLE_CATEGORY_FAILURE:
		case ARTICLE_CATEGORY_SUCCESS:
			return {
				...state,
				categories: action.payload,
				errors: {},
			};
		case ARTICLE_TAG_FAILURE:
		case ARTICLE_TAG_SUCCESS:
			return {
				...state,
				tags: action.payload,
				errors: {},
			};
		default:
			return state;
	}
};

export default reducer;
