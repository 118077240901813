const WidgetsData = [
	{
		id: 1,
		title: "My Wallet",
		price: 865.2,
		rank: "+$20.9k",
		isDoller: true,
		postFix: "k",
		statusColor: "success",
		series: [
			2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15, 2, 10, 18,
			22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15, 2, 10, 18, 22, 36,
			15, 47, 75, 65, 19, 14, 2, 47, 42, 15, 2, 10, 18, 22, 36, 15, 47,
			75, 65, 19, 14, 2, 47, 42, 15, 2, 10, 18, 22, 36, 15, 47, 75, 65,
			19, 14, 2, 47, 42, 15, 2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2,
			47, 42, 15, 2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42,
			15,
		],
	},
	{
		id: 2,
		title: "Number of Trades",
		price: 6258,
		rank: "-29 Trades",
		isDoller: false,
		statusColor: "danger",
		series: [15, 42, 47, 2, 14, 19, 65, 75, 47, 15, 42, 47, 2, 14, 12],
	},
	{
		id: 3,
		title: "Invested Amount",
		price: 42,
		rank: "+$2.8k",
		isDoller: true,
		postFix: "K",
		statusColor: "success",
		series: [47, 15, 2, 67, 22, 20, 36, 60, 60, 30, 50, 11, 12, 3, 8],
	},
	{
		id: 5,
		title: "Profit Ration",
		price: 1257,
		rank: "+2.75%",
		isDoller: true,
		postFix: "",
		statusColor: "success",
		series: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
	},
];

export { WidgetsData };
