export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const TOTAL_FIAT_TRANSCATION_REQUEST = "TOTAL_FIAT_TRANSCATION_REQUEST";
export const TOTAL_FIAT_TRANSCATION_FAILURE = "TOTAL_FIAT_TRANSCATION_FAILURE";
export const TOTAL_FIAT_TRANSCATION_SUCCESS = "TOTAL_FIAT_TRANSCATION_SUCCESS";

export const TOTAL_CRYPTO_TRANSCATION_REQUEST =
	"TOTAL_CRYPTO_TRANSCATION_REQUEST";
export const TOTAL_CRYPTO_TRANSCATION_FAILURE =
	"TOTAL_CRYPTO_TRANSCATION_FAILURE";
export const TOTAL_CRYPTO_TRANSCATION_SUCCESS =
	"TOTAL_CRYPTO_TRANSCATION_SUCCESS";

export const TOTAL_SELL_ORDERS_REQUEST = "TOTAL_SELL_ORDERS_REQUEST";
export const TOTAL_SELL_ORDERS_FAILURE = "TOTAL_SELL_ORDERS_FAILURE";
export const TOTAL_SELL_ORDERS_SUCCESS = "TOTAL_SELL_ORDERS_SUCCESS";

export const TOTAL_BUY_ORDERS_REQUEST = "TOTAL_BUY_ORDERS_REQUEST";
export const TOTAL_BUY_ORDERS_FAILURE = "TOTAL_BUY_ORDERS_FAILURE";
export const TOTAL_BUY_ORDERS_SUCCESS = "TOTAL_BUY_ORDERS_SUCCESS";

export const TOTAL_USER_REQUEST = "TOTAL_USER_REQUEST";
export const TOTAL_USER_FAILURE = "TOTAL_USER_FAILURE";
export const TOTAL_USER_SUCCESS = "TOTAL_USER_SUCCESS";

export const TOTAL_USER_HOLDING_REQUEST = "TOTAL_USER_HOLDING_REQUEST";
export const TOTAL_USER_HOLDING_FAILURE = "TOTAL_USER_HOLDING_FAILURE";
export const TOTAL_USER_HOLDING_SUCCESS = "TOTAL_USER_HOLDING_SUCCESS";
