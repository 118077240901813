export const TOGGLE_MARKET = "TOGGLE_MARKET";
export const TOGGLE_MARKET_FAILURE = "TOGGLE_MARKET_FAILURE";
export const TOGGLE_MARKET_SUCCESS = "TOGGLE_MARKET_SUCCESS";

export const MARKET_DELETE = "MARKET_DELETE";
export const MARKET_DELETE_FAILURE = "MARKET_DELETE_FAILURE";
export const MARKET_DELETE_SUCCESS = "MARKET_DELETE_SUCCESS";

export const MARKET_UPDATE = "MARKET_UPDATE";
export const MARKET_UPDATE_FAILURE = "MARKET_UPDATE_FAILURE";
export const MARKET_UPDATE_SUCCESS = "MARKET_UPDATE_SUCCESS";

export const MARKET_CREATE = "MARKET_CREATE";
export const MARKET_CREATE_FAILURE = "MARKET_CREATE_FAILURE";
export const MARKET_CREATE_SUCCESS = "MARKET_CREATE_SUCCESS";

export const GET_ACTIVE_MARKET_REQUEST = "GET_ACTIVE_MARKET_REQUEST";
export const GET_ACTIVE_MARKET_FAILURE = "GET_ACTIVE_MARKET_FAILURE";
export const GET_ACTIVE_MARKET_SUCCESS = "GET_ACTIVE_MARKET_SUCCESS";

export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const ERRORS = "ERRORS";
