export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
export const BITEX_SAVING_PLANS = "BITEX_SAVING_PLANS";
export const BITEX_SAVING_PLANS_SUCCESS = "BITEX_SAVING_PLANS_SUCCESS";
export const ACTIVE_BITEX_SAVING_PLANS = "ACTIVE_BITEX_SAVING_PLANS";
export const ACTIVE_BITEX_SAVING_PLANS_SUCCESS =
	"ACTIVE_BITEX_SAVING_PLANS_SUCCESS";

export const CREATE_BITEX_SAVING_PLAN_REQUEST =
	"CREATE_BITEX_SAVING_PLAN_REQUEST";
export const CREATE_BITEX_SAVING_PLAN_SUCCESS =
	"CREATE_BITEX_SAVING_PLAN_SUCCESS";
export const CREATE_BITEX_SAVING_PLAN_FAILURE =
	"CREATE_BITEX_SAVING_PLAN_FAILURE";

export const UPDATE_BITEX_SAVING_PLAN_REQUEST =
	"UPDATE_BITEX_SAVING_PLAN_REQUEST";
export const UPDATE_BITEX_SAVING_PLAN_SUCCESS =
	"UPDATE_BITEX_SAVING_PLAN_SUCCESS";
export const UPDATE_BITEX_SAVING_PLAN_FAILURE =
	"UPDATE_BITEX_SAVING_PLAN_FAILURE";

export const TOGGLE_BITEX_SAVING_PLAN_REQUEST =
	"TOGGLE_BITEX_SAVING_PLAN_REQUEST";
export const TOGGLE_BITEX_SAVING_PLAN_SUCCESS =
	"TOGGLE_BITEX_SAVING_PLAN_SUCCESS";
export const TOGGLE_BITEX_SAVING_PLAN_FAILURE =
	"TOGGLE_BITEX_SAVING_PLAN_FAILURE";

export const REMOVE_BITEX_SAVING_PLAN_REQUEST =
	"REMOVE_BITEX_SAVING_PLAN_REQUEST";
export const REMOVE_BITEX_SAVING_PLAN_SUCCESS =
	"REMOVE_BITEX_SAVING_PLAN_SUCCESS";
export const REMOVE_BITEX_SAVING_PLAN_FAILURE =
	"REMOVE_BITEX_SAVING_PLAN_FAILURE";
