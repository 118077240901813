export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_BITGO_SETTING_REQUEST = "CREATE_BITGO_SETTING_REQUEST";
export const CREATE_BITGO_SETTING_SUCCESS = "CREATE_BITGO_SETTING_SUCCESS";
export const CREATE_BITGO_SETTING_FAILURE = "CREATE_BITGO_SETTING_FAILURE";

export const UPDATE_BITGO_SETTING_REQUEST = "UPDATE_BITGO_SETTING_REQUEST";
export const UPDATE_BITGO_SETTING_SUCCESS = "UPDATE_BITGO_SETTING_SUCCESS";
export const UPDATE_BITGO_SETTING_FAILURE = "UPDATE_BITGO_SETTING_FAILURE";

export const REMOVE_BITGO_SETTING_REQUEST = "REMOVE_BITGO_SETTING_REQUEST";
export const REMOVE_BITGO_SETTING_SUCCESS = "REMOVE_BITGO_SETTING_SUCCESS";
export const REMOVE_BITGO_SETTING_FAILURE = "REMOVE_BITGO_SETTING_FAILURE";

export const LIVE_BITGO_SETTING_REQUEST = "LIVE_BITGO_SETTING_REQUEST";
export const LIVE_BITGO_SETTING_SUCCESS = "LIVE_BITGO_SETTING_SUCCESS";
export const LIVE_BITGO_SETTING_FAILURE = "LIVE_BITGO_SETTING_FAILURE";

export const BITGO_SETTING_REQUEST = "BITGO_SETTING_REQUEST";
export const BITGO_SETTING_SUCCESS = "BITGO_SETTING_SUCCESS";
export const BITGO_SETTING_FAILURE = "BITGO_SETTING_FAILURE";

export const CREATE_XRP_WITHDRAWAL_REQUEST = "CREATE_XRP_WITHDRAWAL_REQUEST";
export const CREATE_XRP_WITHDRAWAL_SUCCESS = "CREATE_XRP_WITHDRAWAL_SUCCESS";
export const CREATE_XRP_WITHDRAWAL_FAILURE = "CREATE_XRP_WITHDRAWAL_FAILURE";

export const UPDATE_XRP_WITHDRAWAL_REQUEST = "UPDATE_XRP_WITHDRAWAL_REQUEST";
export const UPDATE_XRP_WITHDRAWAL_SUCCESS = "UPDATE_XRP_WITHDRAWAL_SUCCESS";
export const UPDATE_XRP_WITHDRAWAL_FAILURE = "UPDATE_XRP_WITHDRAWAL_FAILURE";

export const REMOVE_XRP_WITHDRAWAL_REQUEST = "REMOVE_XRP_WITHDRAWAL_REQUEST";
export const REMOVE_XRP_WITHDRAWAL_SUCCESS = "REMOVE_XRP_WITHDRAWAL_SUCCESS";
export const REMOVE_XRP_WITHDRAWAL_FAILURE = "REMOVE_XRP_WITHDRAWAL_FAILURE";

export const XRP_WITHDRAWAL_REQUEST = "XRP_WITHDRAWAL_REQUEST";
export const XRP_WITHDRAWAL_SUCCESS = "XRP_WITHDRAWAL_SUCCESS";
export const XRP_WITHDRAWAL_FAILURE = "XRP_WITHDRAWAL_FAILURE";

export const CREATE_XRP_DEPOSIT_REQUEST = "CREATE_XRP_DEPOSIT_REQUEST";
export const CREATE_XRP_DEPOSIT_SUCCESS = "CREATE_XRP_DEPOSIT_SUCCESS";
export const CREATE_XRP_DEPOSIT_FAILURE = "CREATE_XRP_DEPOSIT_FAILURE";

export const UPDATE_XRP_DEPOSIT_REQUEST = "UPDATE_XRP_DEPOSIT_REQUEST";
export const UPDATE_XRP_DEPOSIT_SUCCESS = "UPDATE_XRP_DEPOSIT_SUCCESS";
export const UPDATE_XRP_DEPOSIT_FAILURE = "UPDATE_XRP_DEPOSIT_FAILURE";

export const REMOVE_XRP_DEPOSIT_REQUEST = "REMOVE_XRP_DEPOSIT_REQUEST";
export const REMOVE_XRP_DEPOSIT_SUCCESS = "REMOVE_XRP_DEPOSIT_SUCCESS";
export const REMOVE_XRP_DEPOSIT_FAILURE = "REMOVE_XRP_DEPOSIT_FAILURE";

export const XRP_DEPOSIT_REQUEST = "XRP_DEPOSIT_REQUEST";
export const XRP_DEPOSIT_SUCCESS = "XRP_DEPOSIT_SUCCESS";
export const XRP_DEPOSIT_FAILURE = "XRP_DEPOSIT_FAILURE";

export const XRP_WALLET_BALANCE_REQUEST = "XRP_WALLET_BALANCE_REQUEST";
export const XRP_WALLET_BALANCE_SUCCESS = "XRP_WALLET_BALANCE_SUCCESS";
export const XRP_WALLET_BALANCE_FAILURE = "XRP_WALLET_BALANCE_FAILURE";

export const XRP_TRANSFER_AMOUNT_REQUEST = "XRP_TRANSFER_AMOUNT_REQUEST";
export const XRP_TRANSFER_AMOUNT_SUCCESS = "XRP_TRANSFER_AMOUNT_SUCCESS";
export const XRP_TRANSFER_AMOUNT_FAILURE = "XRP_TRANSFER_AMOUNT_FAILURE";


    export const CREATE_ETH_WITHDRAWAL_REQUEST="CREATE_ETH_WITHDRAWAL_REQUEST";
	export const CREATE_ETH_WITHDRAWAL_SUCCESS="CREATE_ETH_WITHDRAWAL_SUCCESS";
	export const CREATE_ETH_WITHDRAWAL_FAILURE="CREATE_ETH_WITHDRAWAL_FAILURE";
	export const UPDATE_ETH_WITHDRAWAL_REQUEST="UPDATE_ETH_WITHDRAWAL_REQUEST";
	export const UPDATE_ETH_WITHDRAWAL_SUCCESS="UPDATE_ETH_WITHDRAWAL_SUCCESS";
	export const UPDATE_ETH_WITHDRAWAL_FAILURE="UPDATE_ETH_WITHDRAWAL_FAILURE";
	export const REMOVE_ETH_WITHDRAWAL_REQUEST="REMOVE_ETH_WITHDRAWAL_REQUEST";
	export const REMOVE_ETH_WITHDRAWAL_SUCCESS="REMOVE_ETH_WITHDRAWAL_SUCCESS";
	export const REMOVE_ETH_WITHDRAWAL_FAILURE="REMOVE_ETH_WITHDRAWAL_FAILURE";
	export const ETH_WITHDRAWAL_REQUEST = "ETH_WITHDRAWAL_REQUEST";
	export const ETH_WITHDRAWAL_SUCCESS = "ETH_WITHDRAWAL_SUCCESS";
	export const ETH_WITHDRAWAL_FAILURE = "ETH_WITHDRAWAL_FAILURE";
	export const CREATE_ETH_DEPOSIT_REQUEST = "CREATE_ETH_DEPOSIT_REQUEST";
	export const CREATE_ETH_DEPOSIT_SUCCESS = "CREATE_ETH_DEPOSIT_SUCCESS";
	export const CREATE_ETH_DEPOSIT_FAILURE = "CREATE_ETH_DEPOSIT_FAILURE";
	export const UPDATE_ETH_DEPOSIT_REQUEST = "UPDATE_ETH_DEPOSIT_REQUEST";
	export const UPDATE_ETH_DEPOSIT_SUCCESS = "UPDATE_ETH_DEPOSIT_SUCCESS";
	export const UPDATE_ETH_DEPOSIT_FAILURE = "UPDATE_ETH_DEPOSIT_FAILURE";
	export const REMOVE_ETH_DEPOSIT_REQUEST = "REMOVE_ETH_DEPOSIT_REQUEST";
	export const REMOVE_ETH_DEPOSIT_SUCCESS = "REMOVE_ETH_DEPOSIT_SUCCESS";
	export const REMOVE_ETH_DEPOSIT_FAILURE = "REMOVE_ETH_DEPOSIT_FAILURE";
	export const ETH_DEPOSIT_REQUEST = "ETH_DEPOSIT_REQUEST";
	export const ETH_DEPOSIT_SUCCESS = "ETH_DEPOSIT_SUCCESS";
	export const ETH_DEPOSIT_FAILURE = "ETH_DEPOSIT_FAILURE";
	export const ETH_WALLET_BALANCE_REQUEST = "ETH_WALLET_BALANCE_REQUEST";
	export const ETH_WALLET_BALANCE_SUCCESS = "ETH_WALLET_BALANCE_SUCCESS";
	export const ETH_WALLET_BALANCE_FAILURE = "ETH_WALLET_BALANCE_FAILURE";
	export const ETH_TRANSFER_AMOUNT_REQUEST = "ETH_TRANSFER_AMOUNT_REQUEST";
	export const ETH_TRANSFER_AMOUNT_SUCCESS = "ETH_TRANSFER_AMOUNT_SUCCESS";
	export const ETH_TRANSFER_AMOUNT_FAILURE = "ETH_TRANSFER_AMOUNT_FAILURE";

export const REFERRAL_REQUEST = "REFERRAL_REQUEST";
export const REFERRAL_SUCCESS = "REFERRAL_SUCCESS";
export const REFERRAL_FAILURE = "REFERRAL_FAILURE";

export const UPDATE_REFERRAL_REQUEST = "UPDATE_REFERRAL_REQUEST";
export const UPDATE_REFERRAL_SUCCESS = "UPDATE_REFERRAL_SUCCESS";
export const UPDATE_REFERRAL_FAILURE = "UPDATE_REFERRAL_FAILURE";

export const CREATE_WALLET_BONUS_REQUEST = "CREATE_WALLET_BONUS_REQUEST";
export const CREATE_WALLET_BONUS_SUCCESS = "CREATE_WALLET_BONUS_SUCCESS";
export const CREATE_WALLET_BONUS_FAILURE = "CREATE_WALLET_BONUS_FAILURE";

export const UPDATE_WALLET_BONUS_REQUEST = "UPDATE_WALLET_BONUS_REQUEST";
export const UPDATE_WALLET_BONUS_SUCCESS = "UPDATE_WALLET_BONUS_SUCCESS";
export const UPDATE_WALLET_BONUS_FAILURE = "UPDATE_WALLET_BONUS_FAILURE";

export const REMOVE_WALLET_BONUS_REQUEST = "REMOVE_WALLET_BONUS_REQUEST";
export const REMOVE_WALLET_BONUS_SUCCESS = "REMOVE_WALLET_BONUS_SUCCESS";
export const REMOVE_WALLET_BONUS_FAILURE = "REMOVE_WALLET_BONUS_FAILURE";

export const TOGGLE_WALLET_BONUS_REQUEST = "TOGGLE_WALLET_BONUS_REQUEST";
export const TOGGLE_WALLET_BONUS_SUCCESS = "TOGGLE_WALLET_BONUS_SUCCESS";
export const TOGGLE_WALLET_BONUS_FAILURE = "TOGGLE_WALLET_BONUS_FAILURE";

export const AGENT_SETTING_REQUEST = "AGENT_SETTING_REQUEST";
export const AGENT_SETTING_SUCCESS = "AGENT_SETTING_SUCCESS";
export const AGENT_SETTING_FAILURE = "AGENT_SETTING_FAILURE";

export const UPDATE_AGENT_SETTING_REQUEST = "UPDATE_AGENT_SETTING_REQUEST";
export const UPDATE_AGENT_SETTING_SUCCESS = "UPDATE_AGENT_SETTING_SUCCESS";
export const UPDATE_AGENT_SETTING_FAILURE = "UPDATE_AGENT_SETTING_FAILURE";

export const CREATE_MARGIN_TRADING_REQUEST = "CREATE_MARGIN_TRADING_REQUEST";
export const CREATE_MARGIN_TRADING_SUCCESS = "CREATE_MARGIN_TRADING_SUCCESS";
export const CREATE_MARGIN_TRADING_FAILURE = "CREATE_MARGIN_TRADING_FAILURE";

export const UPDATE_MARGIN_TRADING_REQUEST = "UPDATE_MARGIN_TRADING_REQUEST";
export const UPDATE_MARGIN_TRADING_SUCCESS = "UPDATE_MARGIN_TRADING_SUCCESS";
export const UPDATE_MARGIN_TRADING_FAILURE = "UPDATE_MARGIN_TRADING_FAILURE";

export const REMOVE_MARGIN_TRADING_REQUEST = "REMOVE_MARGIN_TRADING_REQUEST";
export const REMOVE_MARGIN_TRADING_SUCCESS = "REMOVE_MARGIN_TRADING_SUCCESS";
export const REMOVE_MARGIN_TRADING_FAILURE = "REMOVE_MARGIN_TRADING_FAILURE";

export const TOGGLE_EXCHANGE_SETTING_REQUEST = "TOGGLE_EXCHANGE_SETTING_REQUEST";
export const TOGGLE_EXCHANGE_SETTING_SUCCESS = "TOGGLE_EXCHANGE_SETTING_SUCCESS";
export const TOGGLE_EXCHANGE_SETTING_FAILURE = "TOGGLE_EXCHANGE_SETTING_FAILURE";
