export const TOGGLE_ASSETS = "TOGGLE_ASSETS";
export const TOGGLE_ASSETS_FAILURE = "TOGGLE_ASSETS_FAILURE";
export const TOGGLE_ASSETS_SUCCESS = "TOGGLE_ASSETS_SUCCESS";

export const ASSETS_DELETE = "ASSETS_DELETE";
export const ASSETS_DELETE_FAILURE = "ASSETS_DELETE_FAILURE";
export const ASSETS_DELETE_SUCCESS = "ASSETS_DELETE_SUCCESS";

export const ASSETS_UPDATE = "ASSETS_UPDATE";
export const ASSETS_UPDATE_FAILURE = "ASSETS_UPDATE_FAILURE";
export const ASSETS_UPDATE_SUCCESS = "ASSETS_UPDATE_SUCCESS";

export const ASSETS_CREATE = "ASSETS_CREATE";
export const ASSETS_CREATE_FAILURE = "ASSETS_CREATE_FAILURE";
export const ASSETS_CREATE_SUCCESS = "ASSETS_CREATE_SUCCESS";

export const GET_ASSETS = "GET_ASSETS";
export const GET_ASSETS_FAILURE = "GET_ASSETS_FAILURE";
export const GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS";

export const GET_ACTIVE_ASSETS = "GET_ACTIVE_ASSETS";
export const GET_ACTIVE_ASSETS_FAILURE = "GET_ACTIVE_ASSETS_FAILURE";
export const GET_ACTIVE_ASSETS_SUCCESS = "GET_ACTIVE_ASSETS_SUCCESS";

export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
export const ERRORS = "ERRORS";
