export const TOGGLE_AGENT = "TOGGLE_AGENT";
export const TOGGLE_AGENT_FAILURE = "TOGGLE_AGENT_FAILURE";
export const TOGGLE_AGENT_SUCCESS = "TOGGLE_AGENT_SUCCESS";

export const TOGGLE_SUB_AGENT = "TOGGLE_SUB_AGENT";
export const TOGGLE_SUB_AGENT_FAILURE = "TOGGLE_SUB_AGENT_FAILURE";
export const TOGGLE_SUB_AGENT_SUCCESS = "TOGGLE_SUB_AGENT_SUCCESS";

export const TOGGLE_MARGING_TRADING = "TOGGLE_MARGING_TRADING";
export const TOGGLE_MARGING_TRADING_FAILURE = "TOGGLE_MARGING_TRADING_FAILURE";
export const TOGGLE_MARGING_TRADING_SUCCESS = "TOGGLE_MARGING_TRADING_SUCCESS";

export const USER_BASIC_INFO_REQUEST = "USER_BASIC_INFO_REQUEST";
export const USER_BASIC_INFO_SUCCESS = "USER_BASIC_INFO_SUCCESS";
export const USER_BASIC_INFO_FAILURE = "USER_BASIC_INFO_FAILURE";

export const USER_IDENTITY_INFO_REQUEST = "USER_IDENTITY_INFO_REQUEST";
export const USER_IDENTITY_INFO_SUCCESS = "USER_IDENTITY_INFO_SUCCESS";
export const USER_IDENTITY_INFO_FAILURE = "USER_IDENTITY_INFO_FAILURE";

export const USER_PERSONAL_INFO_REQUEST = "USER_PERSONAL_INFO_REQUEST";
export const USER_PERSONAL_INFO_SUCCESS = "USER_PERSONAL_INFO_SUCCESS";
export const USER_PERSONAL_INFO_FAILURE = "USER_PERSONAL_INFO_FAILURE";

export const UPDATE_USER_PERSONAL_INFO_REQUEST =
	"UPDATE_USER_PERSONAL_INFO_REQUEST";
export const UPDATE_USER_PERSONAL_INFO_SUCCESS =
	"UPDATE_USER_PERSONAL_INFO_SUCCESS";
export const UPDATE_USER_PERSONAL_INFO_FAILURE =
	"UPDATE_USER_PERSONAL_INFO_FAILURE";

export const TOGGLE_VERIFICATION_USER_REQUEST =
	"TOGGLE_VERIFICATION_USER_REQUEST";
export const TOGGLE_VERIFICATION_USER_SUCCESS =
	"TOGGLE_VERIFICATION_USER_SUCCESS";
export const TOGGLE_VERIFICATION_USER_FAILURE =
	"TOGGLE_VERIFICATION_USER_FAILURE";

export const TOGGLE_BLOCK_USER_REQUEST = "TOGGLE_BLOCK_USER_REQUEST";
export const TOGGLE_BLOCK_USER_SUCCESS = "TOGGLE_BLOCK_USER_SUCCESS";
export const TOGGLE_BLOCK_USER_FAILURE = "TOGGLE_BLOCK_USER_FAILURE";

export const USER_BANK_INFO_REQUEST = "USER_BANK_INFO_REQUEST";
export const USER_BANK_INFO_SUCCESS = "USER_BANK_INFO_SUCCESS";
export const USER_BANK_INFO_FAILURE = "USER_BANK_INFO_FAILURE";

export const UPDATE_USER_BANK_INFO_REQUEST = "UPDATE_USER_BANK_INFO_REQUEST";
export const UPDATE_USER_BANK_INFO_SUCCESS = "UPDATE_USER_BANK_INFO_SUCCESS";
export const UPDATE_USER_BANK_INFO_FAILURE = "UPDATE_USER_BANK_INFO_FAILURE";

export const USER_WALLET_INFO_REQUEST = "USER_WALLET_INFO_REQUEST";
export const USER_WALLET_INFO_SUCCESS = "USER_WALLET_INFO_SUCCESS";
export const USER_WALLET_INFO_FAILURE = "USER_WALLET_INFO_FAILURE";

export const USER_TOTAL_FIAT_DEPOSIT_REQUEST =
	"USER_TOTAL_FIAT_DEPOSIT_REQUEST";
export const USER_TOTAL_FIAT_DEPOSIT_SUCCESS =
	"USER_TOTAL_FIAT_DEPOSIT_SUCCESS";
export const USER_TOTAL_FIAT_DEPOSIT_FAILURE =
	"USER_TOTAL_FIAT_DEPOSIT_FAILURE";

export const USER_TOTAL_FIAT_WITHDRAWAL_REQUEST =
	"USER_TOTAL_FIAT_WITHDRAWAL_REQUEST";
export const USER_TOTAL_FIAT_WITHDRAWAL_SUCCESS =
	"USER_TOTAL_FIAT_WITHDRAWAL_SUCCESS";
export const USER_TOTAL_FIAT_WITHDRAWAL_FAILURE =
	"USER_TOTAL_FIAT_WITHDRAWAL_FAILURE";

export const USER_TOTAL_CRYPTO_DEPOSIT_REQUEST =
	"USER_TOTAL_CRYPTO_DEPOSIT_REQUEST";
export const USER_TOTAL_CRYPTO_DEPOSIT_SUCCESS =
	"USER_TOTAL_CRYPTO_DEPOSIT_SUCCESS";
export const USER_TOTAL_CRYPTO_DEPOSIT_FAILURE =
	"USER_TOTAL_CRYPTO_DEPOSIT_FAILURE";

export const USER_TOTAL_CRYPTO_WITHDRAWAL_REQUEST =
	"USER_TOTAL_CRYPTO_WITHDRAWAL_REQUEST";
export const USER_TOTAL_CRYPTO_WITHDRAWAL_SUCCESS =
	"USER_TOTAL_CRYPTO_WITHDRAWAL_SUCCESS";
export const USER_TOTAL_CRYPTO_WITHDRAWAL_FAILURE =
	"USER_TOTAL_CRYPTO_WITHDRAWAL_FAILURE";

export const UPDATE_USER_CHANGE_PASSWORD_REQUEST =
	"UPDATE_USER_CHANGE_PASSWORD_REQUEST";
export const UPDATE_USER_CHANGE_PASSWORD_SUCCESS =
	"UPDATE_USER_CHANGE_PASSWORD_SUCCESS";
export const UPDATE_USER_CHANGE_PASSWORD_FAILURE =
	"UPDATE_USER_CHANGE_PASSWORD_FAILURE";

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";

export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
